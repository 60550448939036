<template>
    <div class="layer-details" :class="{ expanded: expand, 'align-self-start': expand }">
        <div class="panel panel-control" @click="onBack">
            <div class="panel-control-icon"></div>
            <h6>Back</h6>
        </div>

        <component :is="details" :row="row" :expanded="expand" @click.native="onDetailsClick" />
    </div>
</template>

<script>
export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
        expand: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        type() {
            return this.row.properties.layerType;
        },
        details() {
            return () => import(`components/layers/types/${this.type}/details`);
        },
    },
    methods: {
        onBack() {
            this.$emit('back');
        },
        onDetailsClick() {
            this.$emit('click', this.row);
        },
    },
};
</script>
