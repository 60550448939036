<template>
    <div class="layers-wrapper" :class="{ hidden: !visible }">
        <div class="panel panel-control close-mobile-layers-panel">
            <h6 class="panel-control-header">Layers</h6>
            <div class="close-mobile-panel" @click.stop="onClose" />
        </div>
        <a id="hide-layers" class="ml-3" @click.prevent="toggleLayers">{{ layerVisibilityText }}</a>
        <div class="layer-container">
            <layer v-for="layer in layers" :key="layer.id" :layer="layer" :map="map" @selected="onSelected" />
        </div>
        <layer-visibility :layers="layers" :visible="layersVisible" />
    </div>
</template>

<script>
import Layer from 'components/maps/publish/layers/mobile/layer';
import LayerVisibility from '@/components/ui/visibility/layer-visibility';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        Layer,
        LayerVisibility,
    },
    props: {
        map: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        row: null,
        layer: null,
        layersVisible: true,
    }),
    computed: {
        ...mapGetters('map', ['layers', 'center']),
        layerVisibilityText() {
            return this.layersVisible ? 'Hide All' : 'Show All';
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        onClose() {
            this.$emit('close');
        },
        onSelected(layer) {
            this.$emit('selected', layer);
        },
        toggleLayers() {
            this.layersVisible = !this.layersVisible;
        },
    },
};
</script>

<style lang="scss" scoped>
#hide-layers {
    font-family: Avenir;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: #9b9b9b;
}
</style>
