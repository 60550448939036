<template>
    <div class="layer">
        <div class="layer-header-wrapper" @click="onLayerClick">
            <visibility
                active-class="layer-is-visible"
                class="view-panel-item-icon"
                :layer="layer"
                :set-visibility="setVisibility"
                @update="onVisibilityUpdate"
            />
            <h3 class="panel-item-header">
                {{ layer.name }}
                <span v-if="hasFeatures">({{ features | size }})</span>
            </h3>
            <button v-if="isVisible" class="arrow" :class="{ 'layer-expanded': isOpen }"></button>
        </div>

        <component
            v-if="isVisible"
            :is="panel"
            :layer="layer"
            :map="map"
            :class="{ hidden: !isOpen }"
            @rows="onRowsLoaded"
            @click="onRowClicked"
        />

        <component v-if="isVisible" :is="mapComponent" :layer="layer" :map="map" />

        <component v-if="searchComponent != null" :is="searchComponent" :layer="layer" :map="map" />
    </div>
</template>

<script>
import { get, isEmpty } from 'utils/lodash';
import Visibility from 'components/layers/form/visibility';
export default {
    components: {
        Visibility,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        setVisibility: {
            type: Boolean,
        },
    },
    data: () => ({
        isOpen: false,
        rowsLoaded: false,
        features: [],
    }),
    computed: {
        isVisible() {
            return get(this.layer, 'visible', true);
        },
        hasFeatures() {
            return !isEmpty(this.features);
        },
        panel() {
            return () => import(/* webpackChunkName: "layer-view" */ `components/layers/types/${this.layer.type}/view`);
        },
        mapComponent() {
            return () => import(/* webpackChunkName: "layer-map" */ `components/layers/types/${this.layer.type}/map`);
        },
        searchComponent() {
            try {
                return require(`@/components/layers/types/${this.layer.type}/search.vue`).default;
            } catch (e) {
                return null;
            }
        },
    },
    watch: {
        layer: {
            immediate: true,
            handler: 'onLayerChange',
        },
    },
    methods: {
        onLayerClick() {
            if (this.isVisible) {
                this.isOpen = !this.isOpen;
            }
        },
        onRowsLoaded(rows) {
            this.features = rows;
            this.rowsLoaded = true;
        },
        async onLayerChange() {
            if (!this.rowsLoaded) {
                this.features = get(this.layer, 'geoJson.features');
            }
        },
        onRowClicked(row) {
            this.$emit('selected', row, this.layer);
        },
        onVisibilityUpdate(visibility) {
            if (!visibility) {
                this.isOpen = false;
            }
            this.$emit('change-visibility');
        },
    },
};
</script>
