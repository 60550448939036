<template>
    <div class="layer">
        <div class="layer-header-wrapper" @click="onSelected">
            <h3 class="panel-item-header">
                {{ layer.name }}
                <span v-if="hasFeatures">({{ features | size }})</span>
            </h3>
            <visibility active-class="layer-is-visible" class="view-panel-item-icon" :layer="layer" />
        </div>
    </div>
</template>

<script>
import { get, isEmpty } from 'utils/lodash';
import Visibility from 'components/layers/form/visibility';
export default {
    components: {
        Visibility,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        features() {
            return get(this.layer, 'geoJson.features');
        },
        hasFeatures() {
            return !isEmpty(this.features);
        },
    },
    methods: {
        onSelected() {
            this.$emit('selected', this.layer);
        },
    },
};
</script>
