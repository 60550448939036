<script>
import { mapActions } from 'vuex';
export default {
    props: {
        layers: {
            type: Array,
            required: true,
        },
        visible: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        visible() {
            this.toggleLayers();
        },
    },
    methods: {
        ...mapActions('map', ['updateLayer']),
        toggleLayers() {
            this.layers.forEach((layer) => {
                this.updateLayer({
                    ...layer,
                    visible: this.visible,
                });
            });
        },
    },
    render: () => null,
};
</script>
