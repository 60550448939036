var render = function render(){var _vm=this,_c=_vm._self._c;return _c('wrapper',{staticClass:"map-view"},[_vm._t("default"),(_vm.loading)?_c('r-loading-bounce',{staticClass:"h-100"}):_vm._e(),_c('header-nav',{attrs:{"disable-dropdown-menu":"","url":"//relocity.com"}},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"outer-container map-page"},[_c('div',{staticClass:"inner-container"},[(_vm.mapBox)?_c('layers-wrapper',{staticClass:"layer-details-desktop-container h-100",attrs:{"visible":_vm.isLayersPanelVisible,"map":_vm.mapBox}}):_vm._e(),_c('div',{class:{
                    'show-layers-panel': _vm.isLayersPanelVisible,
                    'visiblity-hidden': _vm.loading,
                    'show-mobile-details-panel': _vm.isLayersMobileDetailView,
                    'flex-basis-auto': _vm.isLayersMobileDetailView,
                },attrs:{"id":"map-container"}},[_c('div',{attrs:{"id":"map"}},[_c('div',{staticClass:"toggles"},[_c('button',{staticClass:"layers-panel-toggle mobile-layers-panel-toggle",attrs:{"type":"button"},on:{"click":_vm.onToggleLayersMobilePanel}}),_c('button',{staticClass:"layers-panel-toggle desktop-layers-panel-toggle",attrs:{"type":"button"},on:{"click":_vm.onToggleLayersPanel}})]),_c('map-component',{attrs:{"map-id":_vm.map}})],1)]),(_vm.isMobileLayoutView)?_c('div',{staticClass:"layer-details-mobile-view",class:{
                    'flex-basis-100': _vm.isLayersMobilePanelVisible,
                    'bottom-panel': _vm.isBottomPanelOnlyVisible,
                }},[(_vm.mapBox && _vm.selectedRow)?_c('layers-mobile-details',{attrs:{"row":_vm.selectedRow,"expand":_vm.isExpanded},on:{"click":_vm.onDetailsClick,"back":_vm.onMobileDetailsClose}}):_vm._e(),(_vm.mapBox)?_c('layers-mobile-wrapper',{class:{ hidden: !_vm.isLayersMobilePanelVisible },attrs:{"visible":_vm.isLayersMobilePanelVisible,"map":_vm.mapBox},on:{"close":_vm.onMobilePanelClose}}):_vm._e()],1):_vm._e()],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }