<template>
    <wrapper class="map-view">
        <slot />
        <r-loading-bounce v-if="loading" class="h-100" />
        <header-nav disable-dropdown-menu url="//relocity.com">{{ title }}</header-nav>

        <div class="outer-container map-page">
            <div class="inner-container">
                <layers-wrapper
                    v-if="mapBox"
                    :visible="isLayersPanelVisible"
                    :map="mapBox"
                    class="layer-details-desktop-container h-100"
                />
                <div
                    id="map-container"
                    :class="{
                        'show-layers-panel': isLayersPanelVisible,
                        'visiblity-hidden': loading,
                        'show-mobile-details-panel': isLayersMobileDetailView,
                        'flex-basis-auto': isLayersMobileDetailView,
                    }"
                >
                    <div id="map">
                        <div class="toggles">
                            <!-- Mobile Button -->
                            <button
                                type="button"
                                class="layers-panel-toggle mobile-layers-panel-toggle"
                                @click="onToggleLayersMobilePanel"
                            />
                            <!-- Desktop Button -->
                            <button
                                type="button"
                                class="layers-panel-toggle desktop-layers-panel-toggle"
                                @click="onToggleLayersPanel"
                            />
                        </div>

                        <map-component :map-id="map" />
                    </div>
                </div>
                <div
                    v-if="isMobileLayoutView"
                    class="layer-details-mobile-view"
                    :class="{
                        'flex-basis-100': isLayersMobilePanelVisible,
                        'bottom-panel': isBottomPanelOnlyVisible,
                    }"
                >
                    <layers-mobile-details
                        v-if="mapBox && selectedRow"
                        :row="selectedRow"
                        :expand="isExpanded"
                        @click="onDetailsClick"
                        @back="onMobileDetailsClose"
                    />
                    <layers-mobile-wrapper
                        v-if="mapBox"
                        :visible="isLayersMobilePanelVisible"
                        :map="mapBox"
                        :class="{ hidden: !isLayersMobilePanelVisible }"
                        @close="onMobilePanelClose"
                    />
                </div>
            </div>
        </div>
    </wrapper>
</template>

<script>
import store from 'store';
import { head, isEmpty, filter, includes } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
import Wrapper from 'layouts/main';
import MapComponent from 'components/maps/map';
import HeaderNav from 'components/layout/header';
import LayersWrapper from 'components/maps/publish/layers/wrapper';
import LayersMobileWrapper from 'components/maps/publish/layers/mobile/wrapper';
import LayersMobileDetails from 'components/maps/publish/layers/mobile/details';

export default {
    components: {
        Wrapper,
        HeaderNav,
        MapComponent,
        LayersWrapper,
        LayersMobileWrapper,
        LayersMobileDetails,
    },
    props: {
        map: {
            type: [String, Number],
            required: true,
        },
    },
    data: () => ({
        loading: true,
        mapBox: null,
        selectedRow: null,
        isExpanded: false,
        isLayersPanelVisible: true,
        isLayersMobileDetailView: false,
        isLayersMobilePanelVisible: false,
    }),
    computed: {
        ...mapGetters('map', ['title']),
        isMobileLayoutView() {
            return this.$mq == 'md';
        },
        isBottomPanelOnlyVisible() {
            return !this.isLayersMobilePanelVisible && !this.isExpanded;
        },
    },
    watch: {
        map: {
            immediate: true,
            handler: 'fetchMapData',
        },
        isLayersPanelVisible() {
            this.resizeMap();
        },
        selectedRow() {
            this.resizeMap();
        },
        isExpanded() {
            this.resizeMap();
        },
        isLayersMobileDetailView() {
            this.resizeMap();
        },
    },
    metaInfo() {
        return {
            title: this.title,
        };
    },
    created() {
        this.setPublic(true);
        this.$events.$on('map:loaded', this.onMapLoaded);
        this.$events.$on('map:marker:click', this.onMarkerClicked);
        this.$events.$on('map:polygon:click', this.onPolygonClicked);
        this.$events.$on('map:marker:deselect', this.onDeselectMarker);
    },
    beforeDestroy() {
        this.$events.$off('map:loaded', this.onMapLoaded);
        this.$events.$off('map:marker:click', this.onMarkerClicked);
        this.$events.$off('map:polygon:click', this.onPolygonClicked);
        this.$events.$off('map:marker:deselect', this.onDeselectMarker);
    },
    methods: {
        ...mapActions('map', ['loadMap', 'setPublic']),
        resizeMap() {
            this.$nextTick(() => this.mapBox.map.resize());
        },
        async fetchMapData() {
            await this.loadMap({
                map: this.map,
            });

            this.loading = false;
        },
        onMapLoaded(mapBox) {
            store.map = mapBox.map;
            this.mapBox = mapBox;

            this.registerEvents();
        },
        registerEvents() {
            let map = this.mapBox.map;
            map.on('styleimagemissing', (e) => {
                map.loadImage(
                    'https://' + process.env.VUE_APP_RELOCITY_CDN + `/assets/map-icons/${e.id}.png`,
                    (error, image) => {
                        if (error) throw error;
                        map.addImage(e.id, image);
                    }
                );
            });
            map.on('click', (e) => {
                let features = filter(
                    map.queryRenderedFeatures([
                        [e.point.x - 15, e.point.y - 15],
                        [e.point.x + 15, e.point.y + 15],
                    ]),
                    (feature) => {
                        return (
                            includes(feature.layer.id, 'SCHOOLS') ||
                            includes(feature.layer.id, 'AREA_TOUR') ||
                            includes(feature.layer.id, 'CUSTOM') ||
                            includes(feature.layer.id, 'COMMUTE_RADIUS') ||
                            includes(feature.layer.id, 'DESTINATION') ||
                            includes(feature.layer.id, 'DATA_CONTENT')
                        );
                    }
                );

                let feature = head(features);

                if (!isEmpty(feature)) {
                    if (includes(feature.layer.id, 'COMMUTE_RADIUS')) {
                        this.$events.$emit('map:polygon:click', feature, e.lngLat);
                    } else if (includes(feature.layer.id, 'DESTINATION')) {
                        this.$events.$emit('map:destination:click', feature, e.lngLat);
                    } else {
                        this.$events.$emit('map:marker:click', feature);
                    }
                }
            });
        },
        onToggleLayersPanel() {
            this.isLayersPanelVisible = !this.isLayersPanelVisible;
        },
        onToggleLayersMobilePanel() {
            this.isLayersMobilePanelVisible = !this.isLayersMobilePanelVisible;
        },
        onMobilePanelClose() {
            this.isLayersMobilePanelVisible = false;
        },
        onMobileDetailsClose() {
            this.isExpanded = false;
        },
        onMarkerClicked(feature) {
            if (!isEmpty(feature.properties)) {
                this.selectedRow = feature;
                this.isLayersMobileDetailView = true;

                let map = this.mapBox.map;

                if (map.getZoom() < 14) {
                    map.flyTo({
                        center: feature.geometry.coordinates,
                        speed: 2,
                        zoom: 14,
                    });

                    map.once('zoomend', () => this.mapBox.map.resize());
                }

                if (!feature.properties.cluster) {
                    this.$events.$emit('map:popup:enter', {
                        coordinates: feature.geometry.coordinates,
                        html: this.getFeatureDisplayName(feature),
                    });
                }
            }
        },
        getFeatureDisplayName(feature) {
            // data-content features stores it's name in display_details
            return feature.properties.display_details
                ? JSON.parse(feature.properties.display_details).name
                : feature.properties.name;
        },
        onPolygonClicked(feature, coordinates) {
            if (!isEmpty(feature.properties)) {
                let popupText = `${feature.properties.contour} mins`;
                this.$events.$emit('map:popup:enter', {
                    coordinates: coordinates,
                    html: `<div><small>commute</small></div><b>${popupText}</b>`,
                    options: { closeOnMove: true, closeButton: false },
                });
            }
        },
        onDetailsClick() {
            this.isExpanded = true;
        },
        onDeselectMarker() {
            this.selectedRow = null;
        },
    },
};
</script>

<style lang="scss">
.map-view {
    .bottom-panel {
        width: 100%;
        bottom: 0;
    }

    .location_indicator {
        padding-top: 30px;
    }
}
</style>
