<template>
    <div class="layer-details layer-details-desktop-view">
        <div class="panel panel-control" @click="onBack">
            <div class="panel-control-icon" />
            <h6>Back</h6>
        </div>

        <component :is="details" :row="row" :category="category" expanded />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            category: {},
        };
    },
    computed: {
        ...mapGetters('categories', ['findCategoryBySlug']),
        details() {
            return () =>
                import(
                    /* webpackChunkName: "layer-row-details" */ `components/layers/types/${this.layer.type}/details`
                );
        },
    },
    created() {
        this.category = this.findCategoryBySlug(this.layer.category);
    },
    methods: {
        onBack() {
            this.$emit('back');
        },
    },
};
</script>
