<template>
    <div>
        <slot />

        <portal-target name="root-portal-target" />
    </div>
</template>

<script>
export default {};
</script>
