<template>
    <div :class="{ hidden: !visible }">
        <layer-details v-if="row && !isMobileLayoutView" :layer="layer" :row="row" @back="onCloseDetails" />
        <div class="layers-wrapper">
            <a id="hide-layers" @click.prevent="toggleLayers" class="ml-2">{{ layerVisibilityText }}</a>
            <div class="panel panel-control close-mobile-layers-panel">
                <div class="close-mobile-panel" />
                <h6>Back</h6>
            </div>
            <layer
                v-for="layer in visibleLayers"
                :key="layer.id"
                :layer="layer"
                :map="map"
                :set-visibility="isVisible"
                @selected="onSelected"
                @change-visibility="updateVisibility"
            />
        </div>
        <layer-visibility :layers="layers" :visible="layersVisible" />
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import Layer from './layer';
import { mapGetters, mapState, mapActions } from 'vuex';
import LayerDetails from './details';
import LayerVisibility from '@/components/ui/visibility/layer-visibility';
export default {
    components: {
        Layer,
        LayerDetails,
        LayerVisibility,
    },
    props: {
        map: {
            type: Object,
            required: true,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        row: null,
        layer: null,
        isVisible: true,
        layersVisible: true,
    }),
    computed: {
        ...mapGetters('map', ['layers', 'center']),
        ...mapState('categories', ['categories']),
        ...mapGetters('categories', ['findCategoryBySlug']),
        visibleLayers() {
            return this.layers.filter(
                (layer) => layer.type != 'data_content' || this.findCategoryBySlug(layer.category)?.active == true
            );
        },
        isMobileLayoutView() {
            return this.$mq == 'md';
        },
        layerVisibilityText() {
            return this.layersVisible ? 'Hide All' : 'Show All';
        },
    },
    created() {
        this.getAllCategories();
        this.$events.$on('map:marker:click', this.onMapMarkerClicked);
    },
    beforeDestroy() {
        this.$events.$off('map:marker:click', this.onMapMarkerClicked);
    },
    methods: {
        ...mapActions('categories', { getAllCategories: 'all' }),
        ...mapActions('map', ['updateLayer']),
        onMapMarkerClicked(feature) {
            this.row = null;
            this.layer = null;
            this.$nextTick(function () {
                this.onSelected(feature, JSON.parse(get(feature, 'properties.layer')));
            });
        },
        onSelected(row, layer) {
            this.row = row;
            this.layer = layer;
        },
        onCloseDetails() {
            this.row = null;
            this.layer = null;
            this.$events.$emit('map:popup:leave');
        },
        toggleLayers() {
            this.layersVisible = !this.layersVisible;
        },
        updateVisibility() {
            this.isVisible = true;
        },
    },
};
</script>

<style lang="scss" scoped>
#hide-layers {
    cursor: pointer;
    display: inline-block;
    font-family: Avenir;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: #9b9b9b;
}
</style>
