<template>
    <span />
</template>

<script>
import { size, map, join, merge, get, isEmpty, split, debounce, filter } from 'utils/lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import MapService from 'services/mapservice';

export default {
    components: {},
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        category: null,
        clonedLayer: null,
    }),
    watch: {
        categoriesLoaded: {
            immediate: true,
            handler: 'categoryLoadedUpdate',
        },
    },
    computed: {
        ...mapState('categories', ['categoriesLoaded']),
        ...mapState('records', ['dataContentRecords']),
        ...mapGetters('categories', ['findCategoryBySlug', 'findDataContentCategory']),
        ...mapGetters('map', ['center', 'config']),
        dataContentCategoriesProviders() {
            return map(this.findDataContentCategory(this.category.slug)?.providers, 'data_content_provider_id');
        },
        providers() {
            let providers = get(this.clonedLayer, 'providers');

            if (!isEmpty(providers)) {
                return providers;
            }

            return this.dataContentCategoriesProviders;
        },
        polygon() {
            return this.clonedLayer?.filters?.geometry;
        },
        hasPolygon() {
            return size(this.polygon) !== 0 && this.polygon.type === 'Polygon';
        },
        layerId() {
            return this.clonedLayer.name + '__DATA_CONTENT__';
        },
        sourceId() {
            return this.clonedLayer.name + '__DATA_CONTENT_SOURCE__';
        },
    },
    created() {
        this.clonedLayer = this.layer;
        this.$events.$on('layer:updated', this.onLayerUpdated);
        this.$events.$on('map:position:update', this.onMapPositionUpdate);
    },
    beforeDestroy() {
        this.clonedLayer = null;
        this.$events.$off('layer:updated', this.onLayerUpdated);
        this.$events.$off('map:position:update', this.onMapPositionUpdate);
    },
    methods: {
        ...mapActions('records', ['searchUrl', 'searchDataContent', 'syncDataContentFavorites']),
        async onMapPositionUpdate(data) {
            // only react to updates to our map
            if (this.map.map == data.map) {
                this.fetch();
            }
        },
        async fetch() {
            await this.searchDataContent({
                category: this.category.slug,
                layerId: this.layer.id,
                query: this.searchQueryParams(),
            });

            this.syncFavorites();
        },
        syncFavorites() {
            const favoriteAnnotations = filter(this.config.layers, (layer) => layer.id == this.layer.id)[0]
                ?.annotations;
            this.syncDataContentFavorites({ layerId: this.layer.id, favoriteAnnotations, destructive: true });
        },
        searchQueryParams() {
            const resultsQuery = { results: 100, geojson: 1 };

            if (this.hasPolygon) {
                return merge({}, this.polygonQuery(), this.filtersQuery(), this.boundingBoxQuery(), resultsQuery);
            }

            return merge({}, this.pointQuery(), this.filtersQuery(), this.boundingBoxQuery(), resultsQuery);
        },
        boundingBoxQuery() {
            const boundingBox = MapService.getBoundingBox(this.map.map);
            const filters = {};
            map(boundingBox, (coordinates, index) => {
                filters[`filter[boundingBox][${index}]`] = `${coordinates.lng},${coordinates.lat}`;
            });

            return filters;
        },
        polygonQuery() {
            if (!this.hasPolygon) {
                return null;
            }

            const { coordinates } = this.polygon;
            let filters = {};
            map(coordinates[0], (polygon, index) => {
                filters[`filter[polygon][${index}]`] = join(polygon, ',');
            });

            return filters;
        },
        pointQuery() {
            return {
                'filter[point]': join(this.center, ','),
            };
        },
        filtersQuery() {
            let filters = {};
            map(this.clonedLayer?.filters?.fields, (value, id) => {
                filters[`filter[fields][${id}]`] = value;
            });

            if (!isEmpty(this.providers)) {
                filters['filter[providers]'] = this.providers;
            }

            return filters;
        },
        async categoryLoadedUpdate(loaded) {
            if (!loaded) {
                return;
            }

            this.category = await this.findCategoryBySlug(this.layer.category);
            this.fetch();
        },
        parseLayerId(id) {
            return split(id, '-');
        },
        getLayerId(id) {
            return get(this.parseLayerId(id), 0) || id;
        },
        isCurrentLayer(layer) {
            return this.getLayerId(layer.id) === this.getLayerId(this.clonedLayer.id);
        },
        onLayerUpdated: debounce(async function (layer) {
            // ignore layer updates that don't belong to this layer
            if (!this.isCurrentLayer(layer)) {
                return;
            }

            this.clonedLayer = layer;
            this.category = await this.findCategoryBySlug(layer.category);
            await this.fetch();
        }, 100),
    },
};
</script>
