<template>
    <div>
        <r-button close :class="classes" @click.stop="onToggle" />
    </div>
</template>

<script>
import { get } from 'utils/lodash';
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        layer: {
            type: Object,
            required: true,
        },
        activeClass: {
            type: String,
            default: 'layer-is-active',
        },
        setVisibility: {
            type: Boolean,
        },
    },
    computed: {
        ...mapGetters('map', ['user', 'isPublic']),
        isVisible() {
            return get(this.layer, 'visible', true);
        },
        classes() {
            let cls = ['view-panel-item-icon'];
            if (this.isVisible) {
                cls.push(this.activeClass);
            }

            return cls.join(' ');
        },
    },
    watch: {
        setVisibility: {
            handler: 'hideLayer',
        },
    },
    methods: {
        ...mapActions('map', ['saveLayer', 'updateLayer']),
        async onToggle() {
            if (this.isPublic) {
                await this.updateLayer({
                    ...this.layer,
                    visible: !this.isVisible,
                });
            } else {
                await this.saveLayer({
                    ...this.layer,
                    visible: !this.isVisible,
                });
            }
            this.$emit('update', this.isVisible);
        },
        async hideLayer() {
            await this.updateLayer({
                ...this.layer,
                visible: false,
            });
            this.$emit('update', false);
        },
    },
};
</script>
